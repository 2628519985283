@import "../../../assets/styles/variables.scss";

.Entity_Counts {
  padding: 70px 0;
  background: #C2D7E9;

  .Entity_Counts_Column {
    .Entity_Counts_Count {
      font-weight: 600;
      font-size: 4em;
      line-height: 62px;
      color: $secondary-color;
      margin-bottom: 32px;
      display: inline-block;
    }

    .Entity_Counts_Title {
      font-size: 2.2em;
      line-height: 44px;
      color: $secondary-color;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
}

@media (max-width: 1200px) {
  .Entity_Counts {
    .Entity_Counts_Column {
      .Entity_Counts_Title {
        font-size: 2em;
      }
    }
  }
}

@media (max-width: 991px) {
  .Entity_Counts {
    .Entity_Counts_Column {
      text-align: center;
    }
  }
}

@media (max-width: 768px) {
  .Entity_Counts {
    .Entity_Counts_Column {
      margin: 30px 0;
    }
  }
}