@import "../../assets/styles/variables.scss";

.Info_Board {
  background: #FFFFFF;
  border: 1px solid #CFDCE5;
  padding: 42px 40px;
  margin-bottom: 84px;

  .Info_Board_Item {
    text-align: center;

    span {
      font-weight: 600;
      font-size: 2.625em;
      line-height: 52px;
      color: $secondary-color;
    }

    p {
      margin-bottom: 0;
    }
  }
}