@import '../../../assets/styles/variables.scss';

.About {
  padding: 70px 0;

  .About_Title {
    font-weight: bold;
    font-size: 1em;
    line-height: 24px;
    text-transform: uppercase;
    color: $accent-color;
  }

  .About_Sub_Title {
    font-weight: bold;
    font-size: 2.625em;
    line-height: 1.2;
    color: $secondary-color;
    margin-bottom: 20px;
  }

  .About_Description {
    font-size: 1em;
    line-height: 28px;
    color: $secondary-color;
  }

  .See_More_Videos {
    font-size: 1em;
    line-height: 26px;
    color: $secondary-color;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

@media (max-width: 991px) {
  .About {
    .React_Player_Wrap {
      margin: 20px 0;
    }
  }  
}