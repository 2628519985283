@import '../../assets/styles/variables.scss';

.About {
  padding: 144px 0 120px;
  background-color: $white-color;

  .About_Title {
    font-weight: 600;
    font-size: 2.5em;
    line-height: 48px;
    color: $secondary-color;
    margin-bottom: 44px;
  }

  .About_Description {
    font-size: 1.125em;
    line-height: 26px;
    color: $medium-color;
    margin-bottom: 24px;

    a {
      color: $accent-color;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}