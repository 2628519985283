@import "../../../assets/styles/variables.scss";

.Promo {
  .Promo_Text_Wrap {
    background-color: #C2D7E9;
    padding: 180px 400px 180px 88px;

    .Promo_Title {
      font-weight: 600;
      font-size: 2.5em;
      line-height: 48px;
      color: $secondary-color;
      margin-bottom: 22px;
    }

    .Promo_Description {
      font-weight: 500;
      font-size: 1.25em;
      line-height: 30px;
      color: $secondary-color;
      margin-bottom: 31px;
    }

    a {
      width: 250px;
    }
  }

  .Promo_Icons_Wrap {
    padding: 0 133px 0 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
      margin: 25px 0;
      display: inline-block;

      img {
        width: 220px;
      }
    }
  }
}

@media (max-width: 1540px) {
  .Promo {
    .Promo_Text_Wrap {
      padding: 180px 200px 180px 88px;
    }
  
    .Promo_Icons_Wrap {
      padding: 0 133px 0 190px;
    }
  }
}

@media (max-width: 1200px) {
  .Promo {
    .Promo_Text_Wrap {
      padding: 180px 88px 180px 45px;
    }
  
    .Promo_Icons_Wrap {
      padding: 0 45px 0 133px;
    }
  }
}

@media (max-width: 991px) {
  .Promo {
    .Promo_Text_Wrap {
      padding: 70px 15px;
      text-align: center;
    }
  
    .Promo_Icons_Wrap {
      padding: 70px 15px;
      flex-direction: column;
      justify-content: space-around;

      img {
        margin: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .Promo {
    .Promo_Icons_Wrap {
      flex-direction: column;

      img {
        margin: 30px 0;
      }
    }
  }
}