.modal-render-fullscreen .modal-dialog{
    min-height: 100%;
    min-width: 100%;
    padding: 0px;
  }
  .modal-render-fullscreen .modal-dialog{
    margin: 0 auto !important;
  }
  .modal-render-fullscreen .modal-content {
    border-radius: 0;
    min-height: 100vh;
    padding: 0px;
  }
  .modal-render-fullscreen .modal-body {
    padding: 0px;
  }
  .modal-render-fullscreen .jodit-wysiwyg {
    padding: 0px;
  }
  .modal-render-fullscreen .jodit-container {
    border: none;
  }