@import '../../assets/styles/variables.scss';

.Contact_Us {
  padding: 88px 0 137px;

  .Contact_Us_Title {
    font-weight: bold;
    font-size: 2.75em;
    line-height: 1.2;
    color: $secondary-color;
    text-align: center;
    margin-bottom: 64px;
  }
}