@import '../../assets/styles/variables.scss';

.Search_Settings_Wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 46px 0;

  .Total_Count {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 24px;
    margin-bottom: 0;
    color: $neutral-color;

    b {
      color: $secondary-color;
    }
  }

  .Search_Settings_Select_Wrap {
    display: flex;
    align-items: center;

    &:focus-within {
      outline: 1px solid #233539;
    }

    .Search_Settings_Select {
      display: flex;
      align-items: center;
      margin-left: 10px;

      .Search_Settings_Select_Label {
        font-weight: 500;
        font-size: 0.875em;
        line-height: 24px;
        color: $neutral-color;
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .Search_Settings_Wrap {
    flex-direction: column;
  
    .Search_Settings_Select_Wrap {
      justify-content: space-between;
  
      .Search_Settings_Select {
        margin: 10px 20px;
      }
    }
  }
}