.Message_Box {
    border: 1px solid #CFDCE5;
    background-color: #fff;
  
    h6 {
      margin-bottom: 0;
    }
  
    .Message_Header {
      background-color: #F5F8FA;
      padding: 25px 32px;
    }
  
    .Message_Sub_Header {
      display: flex;
      justify-content: space-between;
      padding: 25px 32px;
    }
  
    .Message_Content {
      padding: 25px 32px 160px;
      border-top: 1px solid #F5F8FA;
      border-bottom: 1px solid #F5F8FA;
    }
  
    .Message_Footer {
      padding: 25px 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  
    .Message_Textarea {
      border: none!important;
      padding: 0!important;
    }
  }
a.jodit-status-bar-link {
  display: none!important;
}