.Matching_Reports {
  &_Title {
    margin-bottom: 75px;
  }

  &_Body {
    margin-bottom: 20px;
    padding: 45px 115px 40px 25px;
    background: #fff;
    border: 1px solid #CFDCE5;

    &_Row:first-child {
      margin-bottom: 50px;
    }
  }

  &_Submit {
    text-align: right;
  }
}

.DateRangeWrap {
  position: relative;

  ::placeholder {
    color: black;
  }
}

.icon {
  background: white;
  width: 21px;
  height: 21px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 50px;
}