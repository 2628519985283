@import '../../../assets/styles/variables.scss';

.Search_Opportunities {
  background: #EDF2F3;
  padding: 70px 0;
  text-align: center;

  .Search_Opportunities_Title {
    font-weight: 600;
    font-size: 2.625em;
    line-height: 52px;
    color: $secondary-color;
    margin-bottom: 20px;
  }

  .Search_Opportunities_Sub_Title {
    font-size: 1em;
    line-height: 26px;
    color: $secondary-color;
    margin-bottom: 64px;

    a {
      text-decoration: underline;
      color: $secondary-color;

      &:hover {
        color: $secondary-color;
        text-decoration: none;
      }
    }
  }

  .FullTextSearch {
    display: flex;
    align-items: stretch;
    width: 100%;

    .Search_Input_Wrapper {
      display: flex;
      align-items: flex-start;
      width: 100%;

      .Search_Input {
        width: 100%;
        position: relative;

        input {
          margin-bottom: 0;
          padding-left: 55px;
          border: 1px solid #B6B6B6;
        }

        label {
          display: none;
        }

        svg {
          position: absolute;
          top: 18px;
          left: 27px;
        }

        svg path {
          fill: $accent-color;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .Search_Opportunities {
    .Search_Opportunities_Title {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

@media (max-width: 576px) {
  .Search_Opportunities {
    margin: 0;

    .FullTextSearch {
      flex-direction: column;
      align-items: center;

      .Search_Input_Wrapper {
        margin-bottom: 15px; 
      }

      a {
        padding: 15px 22px;
      }
    }
  }
}
