@import '../../../assets/styles/variables.scss';

.Swiper {
  position: relative;
}

.Overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  text-align: center;
  background: rgba(21, 47, 65, 0.7);
  z-index: 1;

  a {
    width: 250px;
  }

  .Banner_Title {
    font-weight: 600;
    font-size: 5em;
    line-height: 1.3;
    color: $white-color;
  }

  .Banner_Sub_Title {
    font-weight: 400;
    font-size: 2em;
    line-height: 1.3;
    color: $white-color;

    b {
      min-width: 130px;
      display: inline-block;
    }
  }
}

.Banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  padding: 200px 0;
}

@media (max-width: 768px) {
  .Overlay {
    .Banner_Title {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}