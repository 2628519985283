@import '../../../assets/styles/variables.scss';

.EntityButton {
  background: transparent;
  display: flex;
  align-items: center;
  padding: 16px 0;
  margin-right: 85px;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  top: 2px;
  position: relative;

  span {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 1.2;
    color: $secondary-color;
  }
}

.Active {
  border-bottom: 3px solid $accent-color;

  span {
    color: $accent-color;
  }
}

@media (max-width: 768px) {
  .EntityButton {
    margin: 0 30px;
  }
}
