@import '../../assets/styles/variables.scss';

.Table {
  border: 1px solid #CFDCE5;
  border-radius: 8px;

  .Table_Head {
    tr {
      background-color: $white-color;

      th {
        font-weight: 600;
        font-size: 1em;
        line-height: 21px;
        color: $secondary-color;
        border: none;
      }
    }

    svg {
      margin-left: 7px;
    }
  }

  .Table_Body {
    tr:nth-of-type(even) {
      background-color: #F5F8FA;
    }

    tr:nth-of-type(odd) {
      background-color: $white-color;
    }

    .Title_Normal {
      font-size: 0.875em!important;
      line-height: 21px;
      color: $secondary-color;
      font-weight: 500;
    }

    .Title_Bold {
      font-weight: 600;
      font-size: 0.875em;
      line-height: 21px;
      color: $secondary-color;
    }

    .Title_Light {
      font-size: 0.75em;
      line-height: 20px;
      letter-spacing: -0.2px;
      color: $medium-color;
      margin-bottom: 0;
    }
  }

  .No_Wrap {
    white-space: nowrap;
  }

  .Vertical_Middle {
    vertical-align: middle;
  }
}

.Delete_Icon {
  cursor: pointer;
}