@import '../../assets/styles/variables.scss';

.Table {
  border: 1px solid #CFDCE5;
  border-radius: 8px;

  .Table_Head {
    tr {
      background-color: $white-color;

      th {
        font-weight: 600;
        font-size: 1em;
        line-height: 21px;
        color: $secondary-color;
        border: none;
      }
    }

    svg {
      margin-left: 7px;
    }
  }

  .Table_Options {
    width: 30%;
  }

  .Table_Body {
    tr:nth-of-type(even) {
      background-color: #F5F8FA;
    }

    tr:nth-of-type(odd) {
      background-color: $white-color;
    }

    .Title_Normal {
      font-size: 0.875em;
      line-height: 21px;
      color: $secondary-color;
      font-weight: 500;
    }

    .Title_Bold {
      font-weight: 600;
      font-size: 0.875em;
      line-height: 21px;
      color: $secondary-color;
      margin-bottom: 5px;

      a {
        color: $secondary-color;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .Title_Light {
      font-size: 0.75em;
      line-height: 20px;
      letter-spacing: -0.2px;
      color: $medium-color;
      margin-bottom: 0;
    }
  }

  .No_Wrap {
    white-space: nowrap;
  }

  .Vertical_Middle {
    vertical-align: middle;
  }


}

.Custom_Width {
  min-width: 400px;
}


@media (min-width: 993px) and (max-width: 1200px) {
  .Table {
    td {
      a:not(:first-child) {
        margin-left: 10px !important;
      }
    }

    .Vertical_Middle_Email {
      word-wrap: anywhere;
    }
  }

 
}

@media (max-width: 992px) {
  .Table {
    td {
      a {
        margin: 10px 0!important;
      }
    }
  }
}

@media (max-width: 992px) {
  .Table {
    td {
      button {
        margin: 10px 0!important;
      }
    }
  }
}


