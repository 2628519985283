@import '../../../assets/styles/variables.scss';

.React_Player_Wrap {
  position: relative;

  .Blur {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(31, 52, 68, 0.7), rgba(31, 52, 68, 0.7));
    filter: blur(2px);
    border-radius: 8px;
  }

  .Player_Preview_Title {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 26px;
    z-index: 9;
    background-color: $secondary-color;
    color: $white-color;
    padding: 11px;
    text-align: center;
    position: absolute;
    bottom: 0;
    margin: 0;
    left: 0;
    right: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  svg {
    z-index: 10;
  }
}

.Player > div {
  border-radius: 8px;
}

.Hide {
  display: none!important;
}