@import "../../../assets/styles/variables.scss";

.Basic_Info {
  background-color: #EDF2F3;
  padding: 70px 0;

  .Basic_Info_Column {
    width: 100%;
    border-radius: 4px;
    padding: 44px 80px;

    .Basic_Info_Column_Icon {
      margin: 0 auto 32px auto;
      text-align: center;
      display: block;
    }

    .Basic_Info_Column_Title {
      font-weight: 600;
      font-size: 1.75em;
      line-height: 34px;
      text-align: center;
      color: $secondary-color;
      margin-bottom: 32px;
    }

    .Basic_Info_Column_List {
      margin-bottom: 32px;

      .Basic_Info_Column_List_Item {
        margin: 12px 0;
        font-size: 1em;
        line-height: 26px;
        color: $secondary-color;

        .Basic_Info_Column_Check_Icon {
          margin-right: 10px;
        }
      }
    }
  }

  .Basic_Info_Column_Business {
    background-color: $white-color;
    border: 1px solid transparent;
  }

  .Basic_Info_Column_Government {
    border: 1px solid $neutral-color;
  }
}

@media (max-width: 1200px) {
  .Basic_Info {
    .Basic_Info_Column {
      padding: 44px 60px;
    }
  }    
}

@media (max-width: 991px) {
  .Basic_Info {
    .Basic_Info_Column {
      padding: 44px 40px;
    }
  }    
}

@media (max-width: 768px) {
  .Basic_Info {
    .Basic_Info_Column {
      padding: 44px 45px;
      margin: 15px 0;
    }
  }    
}