@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400 !important;700&display=swap") !important;

html {
  font-size: 18px !important;
}

.editor {
  border: 1px solid #e9e9e9 !important;
  color: #000 !important;
  font-family: "Source Sans Pro", sans-serif !important;
}

.editor h2,
.editor h3,
.editor h4 {
  color: #112e51 !important;
}

.editor h2 {
  font-size: 1.555555556rem !important;
}

.editor h3 {
  font-size: 1.333333333rem !important;
}

.editor h4 {
  font-size: 1rem !important;
}

.editor a {
  color: #0071bc !important;
  text-decoration: none !important;
}

.editor a:hover {
  text-decoration: underline !important;
  cursor: pointer !important;
}

pre {
  border: 1px solid #e9e9e9 !important;
  padding: 1rem !important;
  background-color: #f4f4f4 !important;
}

.DraftEditor-editorContainer .public-DraftEditor-content h1 > *{
  font-size: 2rem !important;
  font-weight: bolder !important;
  }
  
  .DraftEditor-editorContainer .public-DraftEditor-content h2 > *{
  font-weight: bolder !important;
  font-size: 1.5rem !important;
  }
  
  .DraftEditor-editorContainer .public-DraftEditor-content h3 > *{
  font-weight: bolder !important;
  font-size: 1.7rem !important;
  
  }
  
  .DraftEditor-editorContainer .public-DraftEditor-content h4 > *{
  font-size: 1rem !important;
  font-weight: bolder !important;
  }
  
  .DraftEditor-editorContainer .public-DraftEditor-content h5 > *{
  font-size: .83rem !important;
  font-weight: bolder !important;
  }
  
  .DraftEditor-editorContainer .public-DraftEditor-content h6 > *{
  font-size: .67rem !important;
  font-weight: bolder !important;
  }

.demo-root {
  width: 90% !important;
  display: flex !important;
  font-family: Roboto !important;
  align-items: center !important;
  flex-direction: column !important;
  justify-content: center !important;
}
.demo-label {
  display: block !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  margin: 50px 0 25px !important;
}
.demo-label-high {
  display: block !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  margin: 50px 0 90px !important;
}
.demo-subLabel {
  margin-bottom: 20px !important;
}
.demo-editorSection {
  display: flex !important;
  justify-content: space-between !important;
}
.demo-wrapper {
  width: 100%  !important;
  display: block  !important;
  margin-bottom: 25px  !important;
  height: auto  !important;
}
.demo-wrapper-medium {
  width: 650px  !important;
  display: block  !important;
  margin-bottom: 50px  !important;
  height: 300px  !important;
}
.demo-editor {
  height: 275px  !important;
  border: 1px solid #F1F1F1  !important;
  padding: 5px  !important;
  border-radius: 2px  !important;
  background-color: #ffffff !important;
}
.DraftEditor-editorContainer {
  
}
.demo-content {
  border: 1px solid #F1F1F1 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  width: 100% !important;
  border-radius: 2px !important;
  resize: none !important;
  height: 200px !important;
}
.demo-toolbar-absolute {
  position: absolute  !important;
  top: -40px  !important;
  width: 550px  !important;
  border-radius: 3px  !important;
  background: #f3f3f3  !important;
  border: 1px solid #e3e3e3  !important;
}
.demo-toolbar-absolute-high {
  position: absolute  !important;
  top: -80px  !important;
  width: 550px  !important;
  border-radius: 3px  !important;
  background: #f3f3f3  !important;
  border: 1px solid #e3e3e3  !important;
}
.demo-editor-plain {
  height: 80%  !important;
  border-width: 0px  !important;
}
.demo-editor-embedded {
  height: 90%  !important;
  border-width: 0px  !important;
}
.demo-wrapper-relative {
  display: block  !important;
  position: relative  !important;
  margin-bottom: 25px  !important;
  height: 200px  !important;
}
.demo-wrapper-relative-long {
  width: 715px  !important;
  display: block  !important;
  position: relative  !important;
  margin-bottom: 25px  !important;
  height: 450px  !important;
}
.bordered-option-classname {
  border: 1px solid #e3e3e3  !important;
}
.demo-toolbar-absolute > div:not(:last-of-type) {
  border-right: 2px solid #e3e3e3 !important;
  padding-right: 5px !important;
}
.demo-toolbar-absolute > div:not(:first-of-type) {
  padding-left: 5px !important;
}
.demo-toolbar-custom {
  border-color: rgb(0,47,126)  !important;
  background-color: #9EBEF5  !important;
}
.demo-editor-custom {
  height: 200px  !important;
  border: 1px solid #F1F1F1  !important;
  padding: 5px  !important;
  height: 75%  !important;
  border-radius: 2px  !important;
  border-color: rgb(0,47,126)  !important;
}
.demo-option-custom {
  width: 15px  !important;
  height: 15px  !important;
  border-color: rgb(0,47,126)  !important;
  color: rgb(0,47,126) !important;
}
.demo-option-custom-wide {
  width: 125px  !important;
  height: 25px  !important;
  border-color: rgb(0,47,126)  !important;
  color: rgb(0,47,126)  !important;
}
.demo-option-custom-medium {
  height: 25px  !important;
  border-color: rgb(0,47,126)  !important;
  color: rgb(0,47,126)  !important;
}
.demo-dropdown-custom {
  border-color: rgb(0,47,126)  !important;
}
.demo-popup-custom {
  border-color: rgb(0,47,126)  !important;
  padding: 5px  !important;
}
.demo-popup-custom input {
  border-color: rgb(0,47,126)  !important;
}
.demo-popup-custom button {
  border-color: rgb(0,47,126)  !important;
}
.demo-section-wrapper {
  display: flex !important;
}
.demo-editor-wrapper {
  width: 60% !important;
}
.demo-section {
  margin: 50px 30px 20px !important;
  width: 95% !important;
}
.ReactCodeMirror {
  width: 38% !important;
  margin-left: 2% !important;
  border: 1px solid #e4e4e4 !important;
  height: 400px !important;
}
.CodeMirror {
  height: 400px  !important;
}
.demo-custom-option {
  width: 26px !important;
  height: 26px !important;
  border: 1px solid #f1f1f1 !important;
  text-align: center !important;
  padding-right: 5px !important;
  padding-bottom: 2px !important;
  cursor: pointer !important;
}

.docs-root {
  width: calc(100% - 150px) !important;
  margin: 30px !important;
}
.docs-label {
  display: block !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  margin: 20px 0 !important;
}
.docs-sub-label {
  display: block !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  margin: 10px 0 !important;
}
.docs-section {
  margin: 20px 0 50px !important;
}
.docs-section .ReactCodeMirror {
  max-height: 500px !important;
  overflow: scroll !important;
  width: 75% !important;
  margin-top: 25px !important;
  margin-left: 0 !important;
  height: auto  !important;
}
.docs-section .CodeMirror {
  height: auto  !important;
}
code {
  padding: 20px !important;
  font-size: 13px !important;
  display: inline-flex !important;
  flex-direction: column !important;
  background-color: #f1f1f1 !important;
}
.code_sm {
  padding: 2px !important;
}
.docs-desc {
  width: 75% !important;
  font-size: 15px !important;
  margin-bottom: 10px !important;
}
.docs-root li {
  margin-bottom: 5px !important;
}
.docs-text {
  width: 75% !important;
  font-size: 17px !important;
  margin: 15px 0 !important;
}
.top-margined {
  margin-top: 20px !important;
}
ol {
  font-size: 15px !important;
}
.docs-property {
  display: flex !important;
}
.author-root {
  margin: 30px !important;
  width: 50% !important;
}
.photo {
  height: 150px !important;
  width: auto !important;
}

.rdw-option-wrapper {
  border: 1px solid #F1F1F1 !important;
  padding: 5px !important;
  min-width: 25px !important;
  height: 20px !important;
  border-radius: 2px !important;
  margin: 0 4px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
  background: white !important;
  text-transform: capitalize !important;
}
.rdw-option-wrapper:hover {
  box-shadow: 1px 1px 0px #BFBDBD !important;
}
.rdw-option-wrapper:active {
  box-shadow: 1px 1px 0px #BFBDBD inset !important;
}
.rdw-option-active {
  box-shadow: 1px 1px 0px #BFBDBD inset !important;
}
.rdw-option-disabled {
  opacity: 0.3 !important;
  cursor: default !important;
}

.rdw-dropdown-wrapper {
  height: 30px !important;
  background: white !important;
  cursor: pointer !important;
  border: 1px solid #F1F1F1 !important;
  border-radius: 2px !important;
  margin: 0 3px !important;
  text-transform: capitalize !important;
  background: white !important;
}
.rdw-dropdown-wrapper:focus {
  outline: none !important;
}
.rdw-dropdown-wrapper:hover {
  box-shadow: 1px 1px 0px #BFBDBD !important;
  background-color: #FFFFFF !important;
}
.rdw-dropdown-wrapper:active {
  box-shadow: 1px 1px 0px #BFBDBD inset !important;
}
.rdw-dropdown-carettoopen {
  height: 0px !important;
  width: 0px !important;
  position: absolute !important;
  top: 35% !important;
  right: 10% !important;
  border-top: 6px solid black !important;
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
}
.rdw-dropdown-carettoclose {
  height: 0px !important;
  width: 0px !important;
  position: absolute !important;
  top: 35% !important;
  right: 10% !important;
  border-bottom: 6px solid black !important;
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
}
.rdw-dropdown-selectedtext {
  display: flex !important;
  position: relative !important;
  height: 100% !important;
  align-items: center !important;
  padding: 0 5px !important;
}
.rdw-dropdown-optionwrapper {
  z-index: 100 !important;
  position: relative !important;
  border: 1px solid #F1F1F1 !important;
  width: 98% !important;
  background: white !important;
  border-radius: 2px !important;
  margin: 0 !important;
  padding: 0 !important;
  max-height: 250px !important;
  overflow-y: scroll !important;
}
.rdw-dropdown-optionwrapper:hover {
  box-shadow: 1px 1px 0px #BFBDBD !important;
  background-color: #FFFFFF !important;
}

.rdw-dropdownoption-default {
  min-height: 25px !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 5px !important;
}
.rdw-dropdownoption-highlighted {
  background: #F1F1F1 !important;
}
.rdw-dropdownoption-active {
  background: #f5f5f5 !important;
}
.rdw-dropdownoption-disabled {
  opacity: 0.3 !important;
  cursor: default !important;
}

.rdw-inline-wrapper {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 6px !important;
  flex-wrap: wrap
}
.rdw-inline-dropdown {
  width: 50px !important;
}
.rdw-inline-dropdownoption {
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
}

.rdw-block-wrapper {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 6px !important;
  flex-wrap: wrap
}
.rdw-block-dropdown {
  width: 110px !important;
}

.rdw-fontsize-wrapper {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 6px !important;
  flex-wrap: wrap
}
.rdw-fontsize-dropdown {
  min-width: 40px !important;
}
.rdw-fontsize-option {
  display: flex !important;
  justify-content: center !important;
}

.rdw-fontfamily-wrapper {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 6px !important;
  flex-wrap: wrap
}
.rdw-fontfamily-dropdown {
  width: 115px !important;
}
.rdw-fontfamily-placeholder {
  white-space: nowrap !important;
  max-width: 90px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.rdw-fontfamily-optionwrapper {
  width: 140px !important;
}

.rdw-list-wrapper {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 6px !important;
  flex-wrap: wrap
}
.rdw-list-dropdown {
  width: 50px !important;
  z-index: 90 !important;
}
.rdw-list-dropdownOption {
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
}

.rdw-text-align-wrapper {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 6px !important;
  flex-wrap: wrap
}
.rdw-text-align-dropdown {
  width: 50px !important;
  z-index: 90 !important;
}
.rdw-text-align-dropdownOption {
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
}
.rdw-right-aligned-block {
  text-align: right !important;
}
.rdw-left-aligned-block {
  text-align: left  !important;
}
.rdw-center-aligned-block {
  text-align: center  !important;
}
.rdw-justify-aligned-block {
  text-align: justify  !important;
}
.rdw-right-aligned-block > div {
  display: inline-block !important;
}
.rdw-left-aligned-block > div {
  display: inline-block !important;
}
.rdw-center-aligned-block > div {
  display: inline-block !important;
}
.rdw-justify-aligned-block > div {
  display: inline-block !important;
}

.rdw-colorpicker-wrapper {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 6px !important;
  position: relative !important;
  flex-wrap: wrap
}
.rdw-colorpicker-modal {
  position: absolute !important;
  top: 35px !important;
  left: 5px !important;
  display: flex !important;
  flex-direction: column !important;
  width: 175px !important;
  height: 175px !important;
  border: 1px solid #F1F1F1 !important;
  padding: 15px !important;
  border-radius: 2px !important;
  z-index: 100 !important;
  background: white !important;
  box-shadow: 3px 3px 5px #BFBDBD !important;
}
.rdw-colorpicker-modal-header {
  display: flex !important;
  padding-bottom: 5px !important;
}
.rdw-colorpicker-modal-style-label {
  font-size: 15px !important;
  width: 50% !important;
  text-align: center !important;
  cursor: pointer !important;
  padding: 0 10px 5px !important;
}
.rdw-colorpicker-modal-style-label-active {
  border-bottom: 2px solid #0a66b7 !important;
}
.rdw-colorpicker-modal-options {
  margin: 5px auto !important;
  display: flex !important;
  width: 100% !important;
  height: 100% !important;
  flex-wrap: wrap !important;
  overflow: scroll !important;
}
.rdw-colorpicker-cube {
  width: 22px !important;
  height: 22px !important;
  border: 1px solid #F1F1F1 !important;
}
.rdw-colorpicker-option {
  margin: 3px !important;
  padding: 0 !important;
  min-height: 20px !important;
  border: none !important;
  width: 22px !important;
  height: 22px !important;
  min-width: 22px !important;
  box-shadow: 1px 2px 1px #BFBDBD inset !important;
}
.rdw-colorpicker-option:hover {
  box-shadow: 1px 2px 1px #BFBDBD !important;
}
.rdw-colorpicker-option:active {
  box-shadow: -1px -2px 1px #BFBDBD !important;
}
.rdw-colorpicker-option-active {
  box-shadow: 0px 0px 2px 2px #BFBDBD !important;
}

.rdw-link-wrapper {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 6px !important;
  position: relative !important;
  flex-wrap: wrap
}
.rdw-link-dropdown {
  width: 50px !important;
}
.rdw-link-dropdownOption {
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
}
.rdw-link-dropdownPlaceholder {
  margin-left: 8px !important;
}
.rdw-link-modal {
  position: absolute !important;
  top: 35px !important;
  left: 5px !important;
  display: flex !important;
  flex-direction: column !important;
  width: 235px !important;
  height: auto !important;
  border: 1px solid #F1F1F1 !important;
  padding: 15px !important;
  border-radius: 2px !important;
  z-index: 100 !important;
  background: white !important;
  box-shadow: 3px 3px 5px #BFBDBD !important;
}
.rdw-link-modal-label {
  font-size: 15px !important;
}
.rdw-link-modal-input {
  margin-top: 5px !important;
  border-radius: 2px !important;
  border: 1px solid #F1F1F1 !important;
  height: 25px !important;
  margin-bottom: 15px !important;
  padding: 0 5px !important;
}
.rdw-link-modal-input:focus {
  outline: none !important;
}
.rdw-link-modal-buttonsection {
  margin: 0 auto !important;
}
.rdw-link-modal-target-option {
  margin-bottom: 20px !important;
}
.rdw-link-modal-target-option > span {
  margin-left: 5px !important;
}
.rdw-link-modal-btn {
  margin-left: 10px !important;
  width: 75px !important;
  height: 30px !important;
  border: 1px solid #F1F1F1 !important;
  border-radius: 2px !important;
  cursor: pointer !important;
  background: white !important;
  text-transform: capitalize !important;
}
.rdw-link-modal-btn:hover {
  box-shadow: 1px 1px 0px #BFBDBD !important;
}
.rdw-link-modal-btn:active {
  box-shadow: 1px 1px 0px #BFBDBD inset !important;
}
.rdw-link-modal-btn:focus {
  outline: none  !important;
}
.rdw-link-modal-btn:disabled {
  background: #ece9e9 !important;
}
.rdw-link-dropdownoption {
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
}
.rdw-history-dropdown {
  width: 50px !important;
}

.rdw-embedded-wrapper {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 6px !important;
  position: relative !important;
  flex-wrap: wrap
}
.rdw-embedded-modal {
  position: absolute !important;
  top: 35px !important;
  left: 5px !important;
  display: flex !important;
  flex-direction: column !important;
  width: 235px !important;
  height: 180px !important;
  border: 1px solid #F1F1F1 !important;
  padding: 15px !important;
  border-radius: 2px !important;
  z-index: 100 !important;
  background: white !important;
  justify-content: space-between !important;
  box-shadow: 3px 3px 5px #BFBDBD !important;
}
.rdw-embedded-modal-header {
  font-size: 15px !important;
  display: flex !important;
}
.rdw-embedded-modal-header-option {
  width: 50% !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}
.rdw-embedded-modal-header-label {
  width: 95px !important;
  border: 1px solid #f1f1f1 !important;
  margin-top: 5px !important;
  background: #6EB8D4 !important;
  border-bottom: 2px solid #0a66b7 !important;
}
.rdw-embedded-modal-link-section {
  display: flex !important;
  flex-direction: column !important;
}
.rdw-embedded-modal-link-input {
  width: 88% !important;
  height: 35px !important;
  margin: 10px 0 !important;
  border: 1px solid #F1F1F1 !important;
  border-radius: 2px !important;
  font-size: 15px !important;
  padding: 0 5px !important;
}
.rdw-embedded-modal-link-input-wrapper {
  display: flex !important;
  align-items: center !important;
}
.rdw-embedded-modal-link-input:focus {
  outline: none !important;
}
.rdw-embedded-modal-btn-section {
  display: flex !important;
  justify-content: center !important;
}
.rdw-embedded-modal-btn {
  margin: 0 3px !important;
  width: 75px !important;
  height: 30px !important;
  border: 1px solid #F1F1F1 !important;
  border-radius: 2px !important;
  cursor: pointer !important;
  background: white !important;
  text-transform: capitalize !important;
}
.rdw-embedded-modal-btn:hover {
  box-shadow: 1px 1px 0px #BFBDBD !important;
}
.rdw-embedded-modal-btn:active {
  box-shadow: 1px 1px 0px #BFBDBD inset !important;
}
.rdw-embedded-modal-btn:focus {
  outline: none  !important;
}
.rdw-embedded-modal-btn:disabled {
  background: #ece9e9 !important;
}
.rdw-embedded-modal-size {
  align-items: center !important;
  display: flex !important;
  margin: 8px 0 !important;
  justify-content: space-between !important;
}
.rdw-embedded-modal-size-input {
  width: 80% !important;
  height: 20px !important;
  border: 1px solid #F1F1F1 !important;
  border-radius: 2px !important;
  font-size: 12px !important;
}
.rdw-embedded-modal-size-input:focus {
  outline: none !important;
}

.rdw-emoji-wrapper {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 6px !important;
  position: relative !important;
  flex-wrap: wrap
}
.rdw-emoji-modal {
  overflow: auto !important;
  position: absolute !important;
  top: 35px !important;
  left: 5px !important;
  display: flex !important;
  flex-wrap: wrap !important;
  width: 235px !important;
  height: 180px !important;
  border: 1px solid #F1F1F1 !important;
  padding: 15px !important;
  border-radius: 2px !important;
  z-index: 100 !important;
  background: white !important;
  box-shadow: 3px 3px 5px #BFBDBD !important;
}
.rdw-emoji-icon {
  margin: 2.5px !important;
  height: 24px !important;
  width: 24px !important;
  cursor: pointer !important;
  font-size: 22px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.rdw-spinner {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100% !important;
  width: 100% !important;
}
.rdw-spinner > div {
  width: 12px !important;
  height: 12px !important;
  background-color: #333 !important;

  border-radius: 100% !important;
  display: inline-block !important;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both !important;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both !important;
}
.rdw-spinner .rdw-bounce1 {
  -webkit-animation-delay: -0.32s !important;
  animation-delay: -0.32s !important;
}
.rdw-spinner .rdw-bounce2 {
  -webkit-animation-delay: -0.16s !important;
  animation-delay: -0.16s !important;
}
@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0) !important;
    transform: scale(0) !important;
  } 40% {
    -webkit-transform: scale(1.0) !important;
    transform: scale(1.0) !important;
  }
}

.rdw-image-wrapper {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 6px !important;
  position: relative !important;
  flex-wrap: wrap
}
.rdw-image-modal {
  position: absolute  !important;
  top: 35px  !important;
  left: 5px  !important;
  display: flex  !important;
  flex-direction: column !important;
  width: 290px  !important;
  border: 1px solid #F1F1F1  !important;
  padding: 15px  !important;
  border-radius: 2px  !important;
  z-index: 100 !important;
  background: white !important;
  box-shadow: 3px 3px 5px #BFBDBD !important;
}
.rdw-image-modal-header {
  font-size: 15px !important;
  margin: 10px 0 !important;
  display: flex !important;
}
.rdw-image-modal-header-option {
  width: 50% !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}
.rdw-image-modal-header-label {
  width: 80px !important;
  background: #f1f1f1 !important;
  border: 1px solid #f1f1f1 !important;
  margin-top: 5px !important;
}
.rdw-image-modal-header-label-highlighted {
  background: #6EB8D4 !important;
  border-bottom: 2px solid #0a66b7 !important;
}
.rdw-image-modal-upload-option {
  width: 100% !important;
  color: gray !important;
  cursor: pointer !important;
  display: flex !important;
  border: none !important;
  font-size: 15px !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #f1f1f1 !important;
  outline: 2px dashed gray !important;
  outline-offset: -10px !important;
  margin: 10px 0 !important;
  padding: 9px 0 !important;
}
.rdw-image-modal-upload-option-highlighted {
  outline: 2px dashed #0a66b7 !important;
}
.rdw-image-modal-upload-option-label {
  cursor: pointer !important;
  height: 100% !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 15px !important;
}
.rdw-image-modal-upload-option-label span{
  padding: 0 20px !important;
}
.rdw-image-modal-upload-option-image-preview {
  max-width: 100% !important;
  max-height: 200px !important;
}
.rdw-image-modal-upload-option-input {
	width: 0.1px !important;
	height: 0.1px !important;
	opacity: 0 !important;
	overflow: hidden !important;
	position: absolute !important;
	z-index: -1 !important;
}
.rdw-image-modal-url-section {
  display: flex !important;
  align-items: center !important;
}
.rdw-image-modal-url-input {
  width: 90%  !important;
  height: 35px  !important;
  margin: 15px 0 12px  !important;
  border: 1px solid #F1F1F1  !important;
  border-radius: 2px  !important;
  font-size: 15px  !important;
  padding: 0 5px  !important;
}
.rdw-image-modal-btn-section {
  margin: 10px auto 0  !important;
}
.rdw-image-modal-url-input:focus {
  outline: none  !important;
}
.rdw-image-modal-btn {
  margin: 0 5px  !important;
  width: 75px  !important;
  height: 30px  !important;
  border: 1px solid #F1F1F1  !important;
  border-radius: 2px  !important;
  cursor: pointer  !important;
  background: white  !important;
  text-transform: capitalize  !important;
}
.rdw-image-modal-btn:hover {
  box-shadow: 1px 1px 0px #BFBDBD !important;
}
.rdw-image-modal-btn:active {
  box-shadow: 1px 1px 0px #BFBDBD inset !important;
}
.rdw-image-modal-btn:focus {
  outline: none  !important;
}
.rdw-image-modal-btn:disabled {
  background: #ece9e9 !important;
}
.rdw-image-modal-spinner {
  position: absolute !important;
  top: -3px !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  opacity: 0.5 !important;
}
.rdw-image-modal-alt-input {
  width: 70% !important;
  height: 20px !important;
  border: 1px solid #F1F1F1 !important;
  border-radius: 2px !important;
  font-size: 12px !important;
  margin-left: 5px !important;
}
.rdw-image-modal-alt-input:focus {
  outline: none !important;
}
.rdw-image-modal-alt-lbl {
  font-size: 12px !important;
}
.rdw-image-modal-size {
  align-items: center !important;
  display: flex !important;
  margin: 8px 0 !important;
  justify-content: space-between !important;
}
.rdw-image-modal-size-input {
  width: 40% !important;
  height: 20px !important;
  margin-bottom: 0px !important;
  border: 1px solid #F1F1F1 !important;
  border-radius: 2px !important;
  font-size: 12px !important;
}
.rdw-image-modal-size-input:focus {
  outline: none !important;
}
.rdw-image-mandatory-sign {
  color: red !important;
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.rdw-remove-wrapper {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 6px !important;
  position: relative !important;
  flex-wrap: wrap
}

.rdw-history-wrapper {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 6px !important;
  flex-wrap: wrap
}
.rdw-history-dropdownoption {
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
}
.rdw-history-dropdown {
  width: 50px !important;
}

.rdw-link-decorator-wrapper {
  position: relative !important;
}
.rdw-link-decorator-icon {
  position: absolute !important;
  left: 40% !important;
  top: 0 !important;
  cursor: pointer !important;
  background-color: white !important;
}

.rdw-mention-link {
  text-decoration: none !important;
  color: #1236ff !important;
  background-color: #f0fbff !important;
  padding: 1px 2px !important;
  border-radius: 2px !important;
}

.rdw-suggestion-wrapper {
  position: relative !important;
}
.rdw-suggestion-dropdown {
  position: absolute !important;
  display: flex !important;
  flex-direction: column !important;
  border: 1px solid #F1F1F1 !important;
  min-width: 100px !important;
  max-height: 150px !important;
  overflow: auto !important;
  background: white !important;
  z-index: 100 !important;
}
.rdw-suggestion-option {
  padding: 7px 5px !important;
  border-bottom: 1px solid #f1f1f1 !important;
}
.rdw-suggestion-option-active {
  background-color: #F1F1F1 !important;
}

.rdw-hashtag-link {
  text-decoration: none !important;
  color: #1236ff !important;
  background-color: #f0fbff !important;
  padding: 1px 2px !important;
  border-radius: 2px !important;
}

.rdw-image-alignment-options-popup {
  position: absolute !important;
  background: white !important;
  display: flex !important;
  padding: 5px 2px !important;
  border-radius: 2px !important;
  border: 1px solid #F1F1F1 !important;
  width: 105px !important;
  cursor: pointer !important;
  z-index: 100 !important;
}
.rdw-alignment-option-left {
  justify-content: flex-start !important;
}
.rdw-image-alignment-option {
  height: 15px !important;
  width: 15px !important;
  min-width: 15px !important;
}
.rdw-image-alignment {
  position: relative !important;
}
.rdw-image-imagewrapper {
  position: relative !important;
}
.rdw-image-center {
  display: flex !important;
  justify-content: center !important;
}
.rdw-image-left {
  display: flex !important;
}
.rdw-image-right {
  display: flex !important;
  justify-content: flex-end !important;
}
.rdw-image-alignment-options-popup-right {
  right: 0 !important;
}

.rdw-editor-main {
  /* height: 100% !important; */
  overflow: auto !important;
  box-sizing: border-box !important;
}
.rdw-editor-toolbar {
  padding: 6px 5px 0 !important;
  border-radius: 2px !important;
  border: 1px solid #F1F1F1 !important;
  display: flex !important;
  justify-content: flex-start !important;
  background: white !important;
  flex-wrap: wrap !important;
  font-size: 15px !important;
  margin-bottom: 5px !important;
  user-select: none !important;
}
.public-DraftStyleDefault-block {
  margin: 1em 0 !important;
}
.rdw-editor-wrapper:focus {
  outline: none !important;
}
.rdw-editor-wrapper {
  box-sizing: content-box !important;
}
.rdw-editor-main blockquote {
  border-left: 5px solid #f1f1f1 !important;
  padding-left: 5px !important;
}
.rdw-editor-main pre {
  background: #f1f1f1 !important;
  border-radius: 3px !important;
  padding: 1px 10px !important;
}