.Password_Input_Wrapper {
  position: relative;
  display: flex;

  svg {
    position: absolute;
    top: 15px;
    right: 1%;
    cursor: pointer;
  }
}

.Edit_Profile_Width {
  width: 60%;

  input[type="password"],
  input[type="text"] {
    width: 100%!important;
  }
}