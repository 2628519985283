@import '../../assets/styles/variables.scss';

.Registration_Check_List {
  margin-bottom: 52px;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    margin: 23px 0;

    svg {
      width: 15px;
    }

    p {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 1.125em;
      line-height: 27px;
      color: $secondary-color;
    }
  }
}

.Frame_Three {
  p {
    span {
      color: #FF2828;
    }
  }
}
