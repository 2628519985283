@import './variables.scss';

// scroll start
html {
  scrollbar-color: $medium-color transparent;
  scrollbar-width: thin;

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #8b8b8b; 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $medium-color; 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $medium-color; 
  }
}
// scroll end

// // temporary disabled location input start
.react-select__control--is-disabled {
  background-color: rgb(242, 242, 242)!important;
  height: 48px;
  position: relative;

  .react-select__value-container {
    height: 48px;

    .css-1g6gooi {
      width: 100%;
    }
  }

  .react-select__indicators {
    border: none!important;
  }
  // temporary disabled location input end
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif!important;
  position: relative;
  background-color: #F4F8F9 !important; 

  .section-spaces {
    margin: 60px 0 144px;
  }

  div,
  section {
    font-size: 16px!important;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }

  p {
    font-size: 1em;
    line-height: 22px;
    color: $secondary-color;
    margin-bottom: 25px;
  }

  h2 {
    font-weight: bold;
    font-size: 2.75em;
    line-height: 120%;
    color: $secondary-color;
    margin-bottom: 14px;
  }

  h3 {
    font-weight: 600;
    font-size: 1.5em;
    line-height: 34px;
    color: $secondary-color;
  }

  h6 {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 150%;
    color: $medium-color;
    margin-bottom: 64px;

    a {
      margin-left: 10px;
      color: $accent-color;
      text-decoration: underline;

      &:hover {
        color: $accent-color;
        text-decoration: none;
      }
    }
  }

  // btns start
  .btnSizes {
    padding: 7.5px 22px;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 0.875em;
    border: none;
    display: inline-block;
    white-space: nowrap;
  }

  .btn-primary {
    &:hover {
      color: $white-color;
      background-image: $black-color;
    }
  }

  .defaultBtn {
    font-weight: 500;
    font-size: 1em;
    line-height: 1.3;
    color: $secondary-color;
    padding: 0.375rem 0.75rem;

    &:hover {
      color: $secondary-color;
    }

    &:focus {
      outline: 1px solid #233539;
    }
  
  }

  .outlineBtn {
    border-radius: 0px;
    &:focus {
      outline: #233539 1px solid !important;
    }
  }

  .filledBtn {
    background: $accent-color;
    color: $white-color;
    border: 1px solid $accent-color;
    
    &:disabled {
      background: $light-color;
      cursor: initial;

      &:hover {
        background: $light-color;
      }
    }

    &:hover {
      background: $white-color;
      color: $accent-color;
    }
  }

  .filledBtn:not([type="submit"]):focus {
    background: $white-color;
      color: $accent-color;
  }

  .transparentBtn {
    background: transparent;
    color: $secondary-color;
    border: 1px solid $secondary-color;
    border-radius: 0px;

    &:disabled {
      background: $light-color;
      cursor: initial;

      &:hover {
        background: $light-color;
      }

    }

    &:hover {
      background: $neutral-color;
      color: $black-color;
    }

    &:focus {
      background: $neutral-color !important;
      color: $black-color;
    }

  }

  .roundedBtn {
    border-radius: 25px;
  }

  // datepicker calendar start

  .date-range-wrap-general {
    svg {
      position: absolute;
      left: 28px;
      top: 16px;
    }
    
    input {
      padding-left: 60px!important;
    }
  }

  .daterangepicker {
    td.in-range {
      background: $accent-color;
    }

    td.start-date,
    td.end-date {
      background: $accent-color;
      color: $white-color;

      &:hover {
        background: $accent-color;
        color: $white-color;
      }
    }
    
    td.today {
      background: $secondary-color;
      color: $white-color;

      &:hover {
        background: $secondary-color;
        color: $white-color;
      }
    }

    .applyBtn {
      padding: 12px 41px;
      text-align: center;
      cursor: pointer;
      background: $accent-color;
      border-radius: 3px;
      color: $white-color;
      font-weight: 500;
      font-size: 1em;
      line-height: 1;
      border: none;
      min-height: 48px;
      min-width: 100px;
    }
  }
  
  ::placeholder {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 21px;
    color: $neutral-color;
  }

  div,
  section {
    input:not([type="checkbox"]),
    textarea {
      padding: 16px 24px;
      background: $white-color;
      box-sizing: border-box;
      height: 56px;
      border: none;
      font-size: 1em!important;
      color: $secondary-color!important;
      margin-bottom: 32px;
      display: block;
      width: 100%;
      outline: none !important;
    }
  
    input:not([type="checkbox"]):focus {
      outline: none !important;
    }
    // general input styles end
  
    input[type="password"] {
      padding-right: 45px!important;
    }

    input:disabled,
    textarea:disabled {
      background-color: rgb(242, 242, 242)!important;
      border: 1px solid transparent!important;
    }
    
    label {
      font-weight: 500;
      font-size: 0.875em;
      line-height: 1.5;
      color: $dark-color;
    }
  
    // checkboxes start
    .terms-wrap {
      margin: 20px 0 54px;
      
      label {
        font-weight: 400;
        font-size: 0.8em;
        line-height: 1.5;
        color: $medium-color!important;
    
        a {
          margin-left: 5px;
          font-weight: 500;
          text-decoration-line: underline;
          color: $black-color;
        }
      }
    }

    .Checkbox_Wrap {
      display: flex;
      align-items: center;
    
      label {
        margin-bottom: 0;
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-weight: 400;
    
        &:before {
          content:'';
          -webkit-appearance: none;
          background-color: transparent;
          border: 1px solid $neutral-color;
          border-radius: 3px;
          padding: 10px;
          display: inline-block;
          position: relative;
          vertical-align: middle;
          cursor: pointer;
          margin-right: 5px;
        }
      }
    
      input {
        margin-right: 10px;
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
      }
    
      input:checked + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: 8px;
        width: 6px;
        height: 14px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }

      input:checked + label:before {
        background-color: $accent-color;
      }
    }

    
    // checkboxes end
  
    // datepicker calendar end
  }

  form {
    input {
      height: 54px!important;
      border: 1px solid $neutral-color!important;

      &:focus {
        border: 1px solid $secondary-color!important;
      }
    }

    input#react-select-3-input {
      border: none!important;
    }

    textarea {
      resize: none!important;
      height: 200px!important;
      border: 1px solid $neutral-color!important;

      &:focus {
        border: 1px solid $secondary-color!important;
      }
    }

    label {
      font-weight: 600;
      font-size: 0.875em;
      line-height: 1.5;
      color: $secondary-color;

      span {
        color: Red;
      }
    }

    .react-select-input-container {
      .react-select-input-focus__control {
        border: 1px solid $secondary-color!important;
      }
    }

    .react-select-input-container {
      .css-yk16xz-control{
        border-style: none!important;
      }

      .react-select-input__control {
        background: $white-color;
        min-height: 54px;
        margin-bottom: 32px;
        border: 1px solid $neutral-color!important;
        border-radius: 0;
        
        .css-b8ldur-Input {
          margin: 0;
          padding: 0;
          width: 100%;
        }

        .react-select-input__placeholder {
          padding-left: 20px;
        }

        .react-select-input__input {
          padding: 0;
          margin: 0;
          height: 54px;
          width: 100%;
          border: none!important;
          border-radius: 0!important;
        }

        .react-select-input__single-value {
          padding: 0 20px;
        }

        .react-select-input__indicators {
          height: 54px;
        }

        input {
          padding: 0 40px 0 20px!important;
          margin: 0!important;
          width: 100%!important;
          box-sizing: border-box!important;
          min-height: 54px;
          border-radius: 0!important;
  
        }

        .react-select-input__value-container {
          width: 100%;
          display: block;
          padding: 0;

          .css-1g6gooi {
            width: 100%;
          }
        }

        .react-select-input__multi-value {
          margin: 5px;
          background: $medium-color;
          border-radius: 15px;
        }

        .react-select-input__multi-value__remove svg path {
          fill: $white-color;
        }

        .react-select-input__multi-value__remove:hover {
          background: transparent;
          cursor: pointer;
        }

        .react-select-input__multi-value__label {
          color: $white-color;
        }
      }

      .css-1pahdxg-control {
        box-shadow: inset 0px 0px 0px 1px #233539;
        border: 1px solid transparent!important;
      }

      .react-select-input__menu .react-select-input__option {
        background: transparent;
        color: $secondary-color;

        &:hover {
          background: $accent-color;
          color: $white-color;
        }

        &:focus {
          background: $accent-color;
          color: $white-color;
        }
      }
    }
  }

  // per page select start

  .per-page-container {
    max-width: 145px;
    width: auto;
    
    .per-page-select__indicator {
      color: $neutral-color;
    }

    .per-page-select {
      min-height: 48px;
      border: none;
      outline: none !important;
      box-shadow: none;
      
      .per-page-select__placeholder {
        padding-left: 20px;
      }
  
      .per-page-select__input {
        padding: 0;
        margin: 0;
        border-right: none;
        height: 48px;
        width: 100%;
        cursor: pointer;
      }
  
      input {
        height: 48px;
        cursor: pointer;
      }
    }
  
    .css-b8ldur-Input {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 48px;
      cursor: pointer;
    }
  
    .per-page-select__value-container {
      height: 48px;
      padding: 0 10px;
      cursor: pointer;
    }
  
    .per-page-select__control {
      background: transparent;
      border: none;
      outline: none !important;
      box-shadow: none;
    }
  
    .per-page-select__indicator-separator {
      display: none;
    }
  
    .per-page-select__menu .per-page-select__option {
      background: transparent;
      color: $secondary-color;
  
      &:hover {
        background: $accent-color;
        color: $white-color;
      }

      &:focus {
        background: $accent-color;
        color: $white-color;
      }
    }
  
    .per-page-select__single-value {
      font-size: 0.875em;
      font-weight: 500;
      color: $secondary-color;
    }
  }

  // per page select end

  //?????
  .pagination-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 44px 0 150px;
  }

  .pagination {
    li {
      margin-right: 0;

      a {
        margin-right: 0;

        &:focus {
          border: 1px solid #233539;
        }
      }
    }
    
    li.previous,
    li.next {
      a {
        font-size: 22px;
        color: $neutral-color;

        &:hover {
          color: $neutral-color;
        }
      }
    }

    li.active a {
      background: $accent-color;
      color: $white-color;

      &:hover {
        color: $white-color;
      }
    }
  
    a {
      font-size: 0.875em;
      line-height: 1;
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $medium-color;
      margin-right: 10px;
    }
  }

  .green-dots-list {
    color: #12BE80;
    list-style-type: disc;
    padding-left: 15px;
    margin-bottom: 85px;

    li {
      margin-bottom: 18px;

      p {
        font-weight: 500;
        font-size: 1.125em;
        line-height: 24px;
        color: $medium-color;
        margin-bottom: 0;

        a {
          font-weight: 500;
          font-size: 1.125em;
          line-height: 24px;
          text-decoration-line: underline;
          color: $secondary-color;

          &:hover {
            text-decoration-line: none;
            color: $secondary-color;
          }
        }
      }

      .add-p {
        font-weight: 400;
        font-size: 1em;
        line-height: 24px;
        color: $medium-color;
        margin-bottom: 0;

        span {
          color: #8D7442;
          background: #fcf3df;
        }
      }
    }
  }

  //form messages start
  .warnMessage {
    color: red;
  }

  .successMessage {
    color: $accent-color;
    text-align: center;
  }

  .tooltip-container,
  .tooltip-container-select {
    position: relative;

    input,
    textarea {
      padding-right: 45px !important;
    }

    svg {
      position: absolute;
      top: 17px;
      right: 3%;
    }

    .react-select__multi-value__remove {
      svg {
        position: static;
      }
    }
  }

  .tooltip-container-select {
    svg {
      top: 19px;
    }
  }

  .tooltip-container-label {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    label {
      margin-bottom: 0;

      a {
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        text-decoration-line: underline;
        color: $secondary-color;

        &:hover {
          text-decoration-line: none;
        }
      }
    }

    svg {
      margin-left: 30px;
    }
  }

  .step-header {
    display: flex;
    align-items: center;
    margin-bottom: 42px;

    h2 {
      margin-bottom: 0;
    }

    .step-container {
      margin-left: 32px;

      p {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 1.5em;
        line-height: 120%;
        color: $secondary-color;

        span {
          color: $accent-color;
          font-weight: 600;
        }
      }
    }
  }

  .frame-btn-wrap {
    display: flex;
    align-items: center;

    button {
      margin-right: 30px;
    }
  }

  .radio-wrapper {
    display: flex;
    align-items: center;
    margin-right: 50px;
    margin-bottom: 32px;

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
    {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: $secondary-color;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 1px solid #ddd;
        border-radius: 100%;
        background: #fff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 18px;
      height: 18px;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 100%;
      border: 4px solid $accent-color;
      right: 0;
      bottom: 0;
    }
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
    }
    [type="radio"]:checked + label:after {
        opacity: 1;
        transform: scale(1);
    }
  }

  .tab-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $secondary-color;
    margin-bottom: 57px;

    .tab-bar-entity-wrap {
      display: flex;
    }
  }

  // notifications start
  .notification-container--top-right {
    top: 80px;
  }

  .notification__item--success {
    background-color: #fff;
    border-left: 4px solid $accent-color;
  }

  .notification__title {
    color: $dark-color;
    font-weight: 500;
    font-size: 1em;
    line-height: 1.3;
  }
  
  .notification__message {
    font-size: 0.875em;
    line-height: 1.3;
    color: #A9ABBD;
  }

  .notification__item--danger {
    background-color: #fff;
    border-left: 4px solid red;
  }

  .notification__item--info {
    background-color: #fff;
    border-left: 4px solid #17a2b8;
  }

  .notification__item {
    border-radius: 0;
  }

  .react-select-container {
    .react-select__control {
      background: transparent;
      min-height: 56px;
      margin-bottom: 32px;
      border: none!important;
      outline: none !important;
      box-shadow: none;
      border-radius: 0;

      .css-b8ldur-Input {
        margin: 0;
        padding: 0;
        width: 100%;
      }

      .react-select__placeholder {
        padding-left: 20px;
        font-weight: 500;
        font-size: 0.875em!important;
        line-height: 21px;
        color: $neutral-color;
      }

      .react-select__input {
        padding: 0;
        margin: 0;
        height: 56px;
        width: 100%;
        border: none!important;
        border-radius: 0!important;
        background-color: $white-color;
      }

      .react-select__single-value {
        padding: 0 20px;
      }

      .react-select__indicators {
        height: 56px;
        display: none;
      }

      input {
        padding: 0 40px 0 20px!important;
        margin: 0!important;
        width: 100%!important;
        box-sizing: border-box!important;
        min-height: 56px;
        border-radius: 0!important;
      }

      .react-select__value-container {
        padding: 0;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: flex-start;
        flex-wrap: wrap-reverse;
        width: 86%;
        overflow: visible;

        .css-1g6gooi {
          width: 100%;
        }
      }

      .react-select__multi-value {
        margin: 5px;
        background: $medium-color;
        border-radius: 15px;
      }

      .react-select__multi-value__remove svg path {
        fill: $white-color;
      }

      .react-select__multi-value__remove:hover {
        background: transparent;
        cursor: pointer;
      }

      .react-select__multi-value__label {
        color: $white-color;
      }
    }

    .react-select__menu .react-select__option {
      background: transparent;
      color: #212529;

      &:hover {
        background: $accent-color;
        color: $white-color;
      }

      &:focus {
        background: $accent-color;
        color: $white-color;
      }
    }
  }

  .react-select-container.advanced {
    width: 60%;
    
    .css-1g6gooi {
      width: 100%;
    }

    .react-select__control {
      margin-bottom: 0;
    }
  }
 
  .modal-dialog {
    max-width: 919px;
    width: 100%;
  }

  .modal-content {
    padding: 48px 90px;
    border-radius: 0;
  }

  .sub-radio-wrap {
    border: 1px solid #CFDCE5;

    .sub-radio-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 42px;

      &:nth-of-type(odd) {
        background: $white-color;
      }
    
      &:nth-of-type(even) {
        background: #F5F8FA;
      }
        
      p, 
      label {
        margin-bottom: 0;
      }

      p {
        position: relative;
        line-height: 20px;
        color: #233539;
        font-size: 0.875em;
      }

      .radio-wrapper {
        margin: 0 0 0 50px;
      }
    }
  }

  .single-page-wrap {
    background: $white-color;
    border: 1px solid #CFDCE5;
    padding: 35px 0 0;

    .single-page-item:nth-of-type(even) {
      background-color: #F5F8FA;
    }

    .single-page-item:nth-of-type(odd) {
      background-color: $white-color;
    }

    h3, a.link {
      margin: 0 35px;
    }

    .single-page-item {
      display: flex;
      padding: 8px 35px;

      h4 {
        width: 30%;
        font-weight: 600;
        font-size: 1em;
        line-height: 24px;
        color: $secondary-color;
        margin-bottom: 0;
      }

      p {
        width: 70%;
        font-size: 1em;
        line-height: 24px;
        color: #233539;
        color: $secondary-color;
        margin-bottom: 0;
      }

     
    }

    .pm {
      div {
        display: flex;
        align-items: center;

        span {  
          color: $white-color;
          background-color: $secondary-color;
          font-weight: 500;
          font-size: 0.875em;
          line-height: 1.5;
          border-radius: 25px;
          padding: 3px 10px;
          margin-right: 5px;
        }
      }
    }
  }

  .advanced-option {
    padding: 25px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:nth-of-type(odd) {
      background: $white-color;
    }

    &:nth-of-type(even) {
      background: #F5F8FA;
    }

    input,
    textarea {
      margin-bottom: 0!important;
      width: 60%!important;
    }
  }

  .advanced-picker-wrap {
    position: relative;
    width: 60%;
  
    input {
      width: 100%!important;
    }
  }

  .general-options-wrap {
    border: 1px solid #CFDCE5;
    margin-bottom: 64px;
    
    h6 {
      width: 30%;
      margin: 0 40px 0 0;
      color: $secondary-color;
    }
  }

  .general {
    border-radius: 20px;
    padding: 1px 6px;
    color: $white-color;
  }
  
  .business {
    background-color: $accent-color;
  }
  
  .officer {
    background-color: $secondary-color;
  }
  
  .admin {
    background-color: $medium-color;
  }

  .active_status {
    background-color: #12BE80;
  }

  .disabled_status {
    background-color: red;
  }

  .responded {
    background-color: #12BE80;
  }

  .not_responded {
    background-color: #FFD738;
  }

 

  .Dropdown {
    .Dropdown_Component {
      width: 100%;
      font-size: 0.8em;
    }

    .Dropdown_Logout {
      color: $white-color;
    }

    .dropdown-item,
    .Dropdown_Logout {
      background-color: transparent!important;
      display: flex;
      align-items: center;
      border: none;
      position: relative;
      font-size: 1em!important;
      padding: 0px;
  
      &:hover,
      &:active {
        background-color: transparent!important;
      }
  
      &:focus {
        outline: none !important;
        box-shadow: none!important;
      }
  
      &:after {
        content: none!important;
      }
  
      .Dropdown_Label {
        font-weight: 500;
        font-size: 1em;
        line-height: 1.3;
        color: $secondary-color;
      }

      & > .Dropdown_Item,
      & .Dropdown_Logout {
        padding: 12px 10px;
        background-color: transparent;
      }

      &:hover > .Dropdown_Item,
      &:hover .Dropdown_Logout {
        background: #B69D73!important;
      }

      &:focus > .Dropdown_Item,
      &:focus .Dropdown_Logout {
        background: #B69D73!important;
      }
    }
    button {
      background-color: transparent!important;
      display: flex;
      align-items: center;
      border: none;
      position: relative;
      font-size: 1em!important;
  
      &:hover,
      &:active {
        background-color: transparent!important;
      }
  
      &:focus {
        outline: none;
        box-shadow: none!important;
      }
  
      &:after {
        content: none!important;
      }
  
      .Dropdown_Label {
        font-weight: 500;
        font-size: 1em;
        line-height: 1.3;
        color: $secondary-color;
      }
  
      svg {
        position: relative;
        top: 2px;
      }
  
      svg path {
        fill: $secondary-color;
      }
    }
  }
  
  .Dropdown_Menu {
    padding: 0!important;
    border-radius: 0!important;
    border: none!important;
    background-color: $secondary-color!important;
    transform: translate(15px, 34px)!important;
  
    button {
      padding: 0!important;
      border-bottom: 1px solid rgba(181, 158, 115, 0.3);
      
       & > span > div,
       & > a {
        padding: 12px 10px!important;
       } 
      
      &:last-of-type {
        border-bottom: none;
      }
    }
    
    .Dropdown_Item {
      width: 100%;

      span {
        font-size: 0.8em!important;
        line-height: 1.2!important;
        color: $white-color!important;
        padding: 0!important;
      }
      
      &:hover {
        background: $accent-color!important;
      }
    }
  }  
  
  .Dropdown { 
    .Arrow {
      &:after {
        content: ""!important; 
        display: inline-block!important;
        margin-left: 0.255em!important;
        vertical-align: 0.255em!important;
        border-top: 0.3em solid $accent-color!important;
        border-right: 0.3em solid transparent!important;
        border-bottom: 0!important;
        border-left: 0.3em solid transparent!important;
      }
    }
  }

  // swiper start
  // .swiper-container {
  //   min-height: 100px;
  //   display: flex;
  //   align-items: center;
  // }

  // .swiper-wrapper {
  //   display: flex;
  //   align-items: center;
  // }

  // .swiper-button-prev,
  // .swiper-button-next {
  //   background-repeat: no-repeat;
  //   background-size: 100% auto;
  //   background-position: center;
  //   width: 20px;
  //   height: 20px;
  //   top: 62%;
  // }

  // .swiper-button-next::after,
  // .swiper-button-prev::after {
  //   display: none;
  // }

  // .swiper-button-next {
  //   background-color: $landing-dark-color;
  //   -webkit-mask:  url(../icons/right-arrow.svg);
  //   mask: url(../icons/right-arrow.svg);
  //   mask-repeat:no-repeat;
  //   mask-size: contain;
  // }

  // .swiper-button-prev {
  //   background-color: $landing-dark-color;
  //   -webkit-mask:  url(../icons/left-arrow.svg);
  //   mask: url(../icons/left-arrow.svg);
  //   mask-repeat:no-repeat;
  //   mask-size: contain;
  // }
  // swiper end

  // .send-again-btn {
  //   cursor: pointer;
  //   font-size: 1em;
  //   line-height: 1.2;
  //   color: $medium-color;
  //   margin-right: 80px;

  //   &:hover {
  //     color: $medium-color;
  //   }
  // }

  // .cancelBtn {
  //   cursor: pointer;
  //   font-size: 1em;
  //   line-height: 1.2;
  //   color: $medium-color;
  //   margin-right: 80px;

  //   &:hover {
  //     color: $medium-color;
  //   }
  // }
  // btns end

    // clear btn/icon start
    // .clearWrap {
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   width: 100%;

    //   h2 {
    //     margin-bottom: 0;
    //     margin-right: 60px;
    //     word-break: break-word;
    //   }

    //   .clearBtn {
    //     font-size: 0.875em;
    //     line-height: 1;
    //     color: $medium-color;
    //     background: none;
    //     border: none;
    //     position: relative;
    //     width: 85px;
    //     display: flex;
    //     align-items: center;
    //     padding-right: 0;
    //   }

    //   .clearBtn.withoutLabel {
    //     width: auto;

    //     svg {
    //       margin-right: 0!important;
    //     }
    //   }
    // }
    // clear btn/icon end

    // hightlighter start
    // .css-1g6gooi {
    //   margin: 0!important;
    //   padding: 0!important;
    //   width: 100%!important;
    // }

    // .highlightEntries {
    //   background-color: $accent-light-color;
    //   padding: 0;
    // }
    // hightlighter end

    // react select library styles start
    

    // react select library styles end

    // react select location library styles start
    // .react-select-container.location input {
    //   padding-left: 40px!important;
    // }

    // .react-select-container.location .react-select__single-value {
    //   padding-left: 40px!important;
    // }

    // .react-select-container.location .react-select__placeholder {
    //   padding-left: 40px!important;
    // }
    // react select location library styles end

    // general input styles start
    

  // user photo start
  // .user-photo {
  //   img {
  //     width: 25px;
  //     height: 25px;
  //     border-radius: 50%;
  //     object-fit: cover;
  //   }
  // }

  // .user-default-photo {
  //   width: 25px;
  //   height: 25px;
  //   border-radius: 50%;
  //   background: $accent-color;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;

  //   span {
  //     font-size: 0.875em;
  //     font-weight: 500;
  //     display: block!important;
  //     margin-left: 0!important;
  //   }
  // }
  // user photo end

  // react select search library styles start
  // .search,
  // .search_location {
  //   width: 100%;

  //   .react-select__control { 
  //     margin-bottom: 0!important;
  //     width: 100%;
  //   }
  // }

  // #search {
  //   .react-select__indicators {
  //     display: none;
  //   }

  //   .react-select__placeholder {
  //     padding-left: 40px!important;
  //   }

  //   .react-select__control {
  //     input {
  //       padding-left: 40px!important;
  //     }
  //   }
  // }
}

@media (max-width: 1920px) {
  .scheduled-table-wrapper {
    display: flex;
    overflow-x: unset;
  }
}

@media (max-width: 1440px) {
  body {
    div,
    section {
      font-size: 15px!important;
    }
  }
}

@media (max-width: 1250px) {
  .scheduled-table-wrapper {
    display: block;
    overflow-x: auto;
  }
  body {
    div,
    section {
      font-size: 15px!important;
    }
  }
}

@media (max-width: 1200px) {
  body {
    div,
    section {
      font-size: 14px!important;
    }
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  body {
    .not_responded {
    display: flex;
    border-radius: 10px;
  }
}
}


@media (max-width: 991px) {
  body {
    div,
    section {
      font-size: 14px!important;
    }

    .not_responded {
      display: block;
      border-radius: 10px;
    }
  }
}

.two-column-div {
  column-count: 2;
 }

@media (max-width: 990px) {
}

@media (max-width: 768px) {
  .two-column-div {
    column-count: 1;
  }
  body {
    div,
    section {
      font-size: 13px!important;
    }

    .per-page-container {
      width: auto;
    }  

    .pagination-wrap {
      flex-direction: column;
    }
  }
}

@media (max-width: 575px) {
  body {
    div,
    section {
      font-size: 12px!important;
    }

    .pagination {
      flex-wrap: wrap;
      justify-content: stretch;
      li {
        margin-right: 0;

        a {
          margin-right: 0;
        }
      }
      
      li.previous,
      li.next {
        a {
          font-size: 22px;
          color: $neutral-color;
  
          &:hover {
            color: $neutral-color;
          }
        }
      }
  
      li.active a {
        background: $accent-color;
        color: $white-color;
  
        &:hover {
          color: $white-color;
        }
      }
    
      a {
        font-size: 0.875em;
        line-height: 1;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $medium-color;
        margin-right: 10px;
      }
    }
  }
}