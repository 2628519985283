$black-color: #000;
$dark-color: #313131;
$medium-color: #787A8F;
$light-color: #D9DCE3;
$accent-light-color: #D7FFEB;
$landing-dark-color: #203A46;

$secondary-color: #233539;
$neutral-color: #B6B6B6;
$medium-color: #565657;
$white-color: #fff;
$accent-color: #B69D73;