@import '../../assets/styles/variables.scss';

.FullTextSearch {
  margin-top: 44px;
  display: flex;
  align-items: stretch;

  .Search_Input_Wrapper {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-right: 0.5rem;

    .Search_Input {
      width: 100%;
      position: relative;

      input {
        margin-bottom: 0;
        padding-left: 55px;
      }

      label {
        display: none;
      }

      svg {
        position: absolute;
        top: 18px;
        left: 27px;
      }

      svg path {
        fill: $neutral-color;
      }
    }
  }

  .SearchDateRange {
    margin: 0 0.5rem 0 0;
    width: 285px;
  }
}

.Date_Picker_Wrap {
  width: 36%;
  position: relative;
}

@media (max-width: 991px) {
  .FullTextSearch {
    .SearchDateRange {
      width: auto;
    }
  }

  .Date_Picker_Wrap {
    width: auto;
  }
}

@media (max-width: 768px) {
  .FullTextSearch {
    flex-direction: column;
    align-items: center;

    .Search_Input_Wrapper {
      margin-right: 0;

      .Search_Input {
        margin-bottom: 15px;
  
        input {
          padding-left: 60px;
        }
      }
    }

    button {
      padding: 15px 35px!important;
    }

    .SearchDateRange {
      width: 100%;
      margin: 0 0 15px 0;
    }
  }

  .Date_Picker_Wrap {
    width: 100%;

    input {
      padding-left: 60px!important;
    }
  }
}