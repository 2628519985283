.No_Wrap {
  white-space: nowrap;
}

.Sort_Icon_Wrap {
  display: flex;
  flex-direction: column;

  .Sort_Icon {
    cursor: pointer;
    width: 10px;
    height: 10px;
  }
}
