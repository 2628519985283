.MessageBox {
    padding: 52px 40px 55px;
    background: #FFFFFF;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;

    h6 {
        margin-bottom: 0;
    }

    .Message_Header {
        background-color: #F5F8FA;
        padding: 25px 32px;
    }

    .Message_Sub_Header {
        display: flex;
        justify-content: space-between;
        padding: 25px 32px;
    }

    .Message_Content {
        padding: 25px 32px 160px;
        border-top: 1px solid #F5F8FA;
        border-bottom: 1px solid #F5F8FA;
    }

    .Message_Footer {
        padding: 25px 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .Message_Textarea {
        border: none !important;
        padding: 0 !important;
    }

    svg {
        margin-bottom: 30px;
    }

    button {
        margin-top: auto;
    }
}

.jodit-wysiwyg{
    min-height: 250px !important;
}

@media (max-width: 991px) {
    .MessageBox {
        margin: 15px 0;
    }
}