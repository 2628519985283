@import '../../assets/styles/variables.scss';

.Alert_Message {
  position:relative;
  background: #FCF3DF;
  padding: 23px 65px 33px 26px;
  margin-top: 50px;
  margin-bottom: 20px;

  p {
    font-weight: 400;
    font-size: 0.9em;
    line-height: 18px;
    color: $medium-color;

    b {
      font-size: 0.9em;
      line-height: 18px;
      color: #8D7442;
    }

    a {
      color: $secondary-color;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  svg {
    margin-right: 8px;
    flex-shrink: 0;
  }
}

.Alert_Message_Title {
  margin-bottom: 20px;
  text-align: center;
}

.Alert_Message_Body {
  display: flex;
  align-items: center;
}

.Alert_Message_Home {
  background: #C2D7E9;

  svg {
    display:none;
  }
}

.Alert_Message_Inner {
  display: flex;
}

.Alert_Message_Close_Button {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor:pointer;
}

.Alert_Message_MatchingReports {
  background: #007BFF;
  padding: 23px 65px 23px 26px;

  p {
    color: white !important;
  }
  svg {
    display:none;
  }
}
