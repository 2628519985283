@import '../../assets/styles/variables.scss';

.Sign_In_Form_Wrap {
  margin: 88px 0 144px;

  .Remember_Forgot_Wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0 34px;
  
    a {
      font-weight: 500;
      font-size: 0.8em;
      line-height: 1.5;
      text-decoration-line: underline;
      color: $neutral-color;
  
      &:hover {
        color: $neutral-color;
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 575px) {
  .Sign_In_Form_Wrap {
    .Sign_In_Form_Header {
      flex-direction: column;
  
      a {
        display: inline-block;
        margin-top: 20px;
      }
    }
  }
}
