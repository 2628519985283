@import '../../assets/styles/variables.scss';

.Check_Box_Container {
  background-color: $white-color;
  padding: 22px 55px 0;
  border: 1px solid #CFDCE5;
}

.Arrow_Icon {
  width: 15px;
  height: 15px;
  color: $accent-color;
  cursor: pointer;
  margin-left: 10px;
}