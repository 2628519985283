@import '../../assets/styles/variables.scss';

.Page_Switcher_Wrap {
  display: flex;
  align-items: center;

  p {
    font-size: 0.875em;
    line-height: 1;
    color: $medium-color;
    margin-bottom: 0;
  }

  .Page_Switcher_Input {
    height: 35px;
    border: 1px solid $dark-color;
    border-radius: 3px;
    width: 60px;
    margin: 0 10px;
    background: transparent;
    padding: 3px 5px;
  }

  span {
    font-size: 0.875em;
    line-height: 1;
    color: $dark-color;
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .Page_Switcher_Wrap {
    margin-top: 30px;
  }
}