.MessageBox {
  padding: 52px 40px 55px;
  background: #FFFFFF;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;

  svg {
    margin-bottom: 30px;
  }

  button {
    margin-top: auto;
  }
}

@media (max-width: 991px) {
  .MessageBox {
    margin: 15px 0;
  }
}